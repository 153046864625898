import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import SideBar from "../other/SideBar";
import NavBar from "../other/NavBar";
import {


    defaultWhatsappApiAccessToken,
    GetFoodersPlans,
    ScriptId,
    ScriptSRC,
    vayureachLink,
} from "../../assets/api/BaseURL";
import LoadingModal from "../../loader/LoadingModal";
import Notification from "../other/Notification";
import { useToast } from "../../../context/ToastContext";
import axios from "axios";

const VarUpdates = () => {
    const navigate = useNavigate();
    const { addToast } = useToast();

    const [token, setToken] = useState("");
    const [toggleSideBar, setToggleSideBar] = useState(false);
    const [canvas, setCanvas] = useState(false);


    const [isLoading, setIsLoading] = useState(false);
    const [currencySymbol, setCurrencySymbol] = useState("");

    const [whatsappApiAccessToken, setWhatsappApiAccessToken] = useState("");
    const [whatsappApiVendorUid, setWhatsappApiVendorUid] = useState("");
    const [whatsappMassageingApiBaseUrl, setWhatsappMassageingApiBaseUrl] = useState("");
    const [whatsappMassageingApiUrl, setWhatsappMassageingApiUrl] = useState("");





    const handleToggleSidebar = (e) => {
        e.preventDefault();
        setToggleSideBar(!toggleSideBar);
    };

    const handleToggleOffCanvas = (e) => {
        e.preventDefault();
        setCanvas(!canvas);
    };





    useEffect(() => {
        if (!localStorage.getItem("token")) {
            navigate("/");
        }
    }, [navigate]);

    useEffect(() => {
        setToken(localStorage.getItem("token"));
        setCurrencySymbol(localStorage.getItem("currencySymbol"));

        setWhatsappApiAccessToken(localStorage.getItem("whatsapp_api_access_token"));
        setWhatsappApiVendorUid(localStorage.getItem("whatsapp_vendor_uid"));
        setWhatsappMassageingApiBaseUrl(localStorage.getItem("whatsapp_massageing_api_base_url"));
        setWhatsappMassageingApiUrl(localStorage.getItem("whatsapp_massageing_api_url"));









    }, [token]);







    useEffect(() => {
        const existingScript = document.getElementById(ScriptId);
        if (!existingScript && localStorage.getItem("userId")) {
            const userId = localStorage.getItem("userId");
            const script = document.createElement("script");
            script.src = ScriptSRC + userId.replace(/"/g, "");
            script.defer = true;
            script.id = ScriptId;
            document.head.appendChild(script);
        }
    }, []);




    useEffect(() => {
        if (token) {

        }
    }, [token]);









    return (
        <>
            {token && (
                <>
                    <div
                        className={
                            toggleSideBar
                                ? "container-scroller sidebar-icon-only"
                                : "container-scroller"
                        }
                    >
                        <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
                            <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                                <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                                    <img src="images/mini-kr-logo.svg" alt="logo" />
                                </Link>
                                <div className="me-1">
                                    <button
                                        className={
                                            toggleSideBar
                                                ? "navbar-toggler navbar-toggler align-self-center"
                                                : "navbar-toggler navbar-toggler align-self-center text-white"
                                        }
                                        type="button"
                                        onClick={handleToggleSidebar}
                                    >
                                        <span className="icon-menu"></span>
                                    </button>
                                </div>
                                <div>
                                    <Link to="/live_orders">
                                        <span className="navbar-brand brand-logo">
                                            <img src="images/Khateraho_logo_white.png" alt="logo" />
                                        </span>
                                    </Link>
                                </div>
                            </div>
                            <NavBar handleToggle={handleToggleOffCanvas} />
                        </nav>
                        <div className="container-fluid page-body-wrapper px-0">
                            <SideBar active="whatsapp_marketing" toggleCanvas={canvas} />

                            <div className="main-panel">
                                <div className="content-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h3 className="ukhd mb-3">WhatsApp Marketing</h3>
                                            <div className="row flex-grow">
                                                <div className="col-12 grid-margin">

                                                    <div className={'my-2'}>
                                                        {whatsappApiAccessToken === defaultWhatsappApiAccessToken || !whatsappApiAccessToken ?
                                                            <div>
                                                                <div>
                                                                    <h5>
                                                                        <b>Instantly connect with your customers and unlock the power of personalized WhatsApp marketing!</b>

                                                                    </h5>
                                                                    <h6>
                                                                        Get your WhatsApp marketing account now by clicking&ensp;
                                                                        <a
                                                                            target="_blank"
                                                                            href={vayureachLink}
                                                                            style={{color:'blue'}}
                                                                        >
                                                                            here.
                                                                        </a>

                                                                    </h6>

                                                                    <br /><br />
                                                                    <img src="./images/whatspp_marketing.png" style={{ 'width': "50vw", borderRadius: "15px" }} /><br /><br />

                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="card card-rounded">
                                                                <div className="card-body trcnt">
                                                                    <h3>API Access Information</h3>
                                                                    <p>To access the available APIs you need access token</p>

                                                                    <br />
                                                                    <div className="col-lg-9">
                                                                        <div className="form-group">
                                                                            <label>
                                                                                API Access Token
                                                                                <span className="text-danger">*</span>
                                                                            </label>
                                                                            <textarea
                                                                                className="form-control"
                                                                                placeholder="Enter Whatsapp Api Access Token"
                                                                                rows="1"
                                                                                name="whatsappApiAccessToken"
                                                                                value={whatsappApiAccessToken}
                                                                                disabled

                                                                            ></textarea>
                                                                        </div>
                                                                    </div>
                                                                    <h6>API Endpoint Information</h6>


                                                                    <div className="col-lg-9">
                                                                        <div className="form-group">
                                                                            <label>
                                                                                API Base URL
                                                                                <span className="text-danger">*</span>
                                                                            </label>
                                                                            <textarea
                                                                                className="form-control"

                                                                                rows="1"
                                                                                name="whatsappMassageingApiBaseUrl"
                                                                                value={whatsappMassageingApiBaseUrl}
                                                                                disabled

                                                                            ></textarea>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-lg-9">
                                                                        <div className="form-group">
                                                                            <label>
                                                                                Example Endpoint for Send Message it will consist of API base url and vendor uid

                                                                                <span className="text-danger">*</span>
                                                                            </label>
                                                                            <textarea
                                                                                className="form-control"
                                                                                value={whatsappMassageingApiUrl + "/contact/send-message"}
                                                                                disabled
                                                                                rows="1"


                                                                            ></textarea>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-lg-9">
                                                                        <div className="form-group">
                                                                            <label>
                                                                                Your Vendor UID

                                                                                <span className="text-danger">*</span>
                                                                            </label>
                                                                            <textarea
                                                                                className="form-control"
                                                                                placeholder="Enter whatsapp Api Vendor Uid"
                                                                                rows="1"
                                                                                name="whatsappApiVendorUid"
                                                                                value={whatsappApiVendorUid}
                                                                                disabled
                                                                            ></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>



                                                            </div>
                                                        }

                                                    </div>




                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <LoadingModal isLoading={isLoading} />

                    <Notification />
                </>
            )}
        </>
    );
};

export default VarUpdates;
