// export const BaseURL = "https://baatcheet.shop/khateraho";
// export const BaseURL = "https://foodersapi.scanka.com";

// export const BaseURL = "https://foodersapi.iwcnlabs.shop";
export const BaseURL = "https://foodersapi.khateraho.com";

export const LoginURL = `${BaseURL}/api/login`;
export const SignupURL = `${BaseURL}/api/signup`;
export const GetStateListURL = `${BaseURL}/api/getState`;
export const GetCityListURL = `${BaseURL}/api/getCity`;
export const SendOTPURL = `${BaseURL}/api/sendOtp`;
export const VerifyOTPURL = `${BaseURL}/api/verify_otp`;
export const GetUserDetailsURL = `${BaseURL}/api/getProfile`;
export const UpdateUserDetailsURL = `${BaseURL}/api/saveProfile`;
export const UpdatePasswordURL = `${BaseURL}/api/updatePassword`;
export const UpdateFooderStatusURL = `${BaseURL}/api/updateFoodersStatus`;
export const ForgotURL = `${BaseURL}/api/forgotPassword`;
export const ResetURL = `${BaseURL}/api/resetPassword`;
/***************************************************************************************/
export const GetCategoryMenuURL = `${BaseURL}/api/getCategory`;
export const SearchCategoryMenuURL = `${BaseURL}/api/searchMenu`;
export const UpdateCategoryMenuStatusURL = `${BaseURL}/api/updateCategoryStatus`;
export const AddCategoryMenuURL = `${BaseURL}/api/saveCategory`;
export const GetEditCategoryMenuURL = `${BaseURL}/api/getSingleCategoryList`;
export const EditCategoryMenuURL = `${BaseURL}/api/updateCategory`;
export const DeleteCategoryMenuURL = `${BaseURL}/api/deleteCategory`;
/***************************************************************************************/
export const GetDishesProductsURL = `${BaseURL}/api/getProduct`;
export const GetAllProductsNameURL = `${BaseURL}/api/GetAllProductsName`;

export const SearchDishesProductsURL = `${BaseURL}/api/searchProduct`;
export const UpdateDishesProductsStatusURL = `${BaseURL}/api/updateProductStatus`;
export const RemoveProductImage = `${BaseURL}/api/removeProductImage`;



export const GetCategoryMenuListURL = `${BaseURL}/api/getCategoryNameList`;
export const AddDishesProductURL = `${BaseURL}/api/saveProduct`;
export const UploadDishesProductPicURL = `${BaseURL}/api/uploadProduct`;
export const DeleteDishesProductsURL = `${BaseURL}/api/deleteProduct`;
export const GetEditDishesProductURL = `${BaseURL}/api/getSingleProductList`;
export const EditDishesProductURL = `${BaseURL}/api/updateProduct`;
export const AddNewProductPropertyURL = `${BaseURL}/api/properties`;
export const AddNewPropertyAttributeURL = `${BaseURL}/api/addPropertiesVaule`;
export const DeleteNewPropertyAttributeURL = `${BaseURL}/api/PropertiesVaule`;
export const DeleteProductPropertyURL = `${BaseURL}/api/Property`;
export const AddProductPropertyCombinationPriceURL = `${BaseURL}/api/addCombination`;

/***************************************************************************************/
export const GetAddonGroupListURL = `${BaseURL}/api/getAddonGroup`;
export const CreateAddonGroupURL = `${BaseURL}/api/saveAddon`;
export const UpdateAddonGroupStatusURL = `${BaseURL}/api/addOnStatusUpdate`;
export const UpdateAddonGroupDataURL = `${BaseURL}/api/qtyUpdateOnAddon`;
export const AddonGroupDataItemAddURL = `${BaseURL}/api/addItemsAddon`;
export const DeleteAddonGroupItemURL = `${BaseURL}/api/addonItems`;
export const DeleteAddonGroupURL = `${BaseURL}/api/addongroup`;
export const DeleteAssignAddonGroupURL = `${BaseURL}/api/assignAddonItems`;
export const AssignAddonGroupToProuductURL = `${BaseURL}/api/assignAddon`;
export const SearchProductListForAddonsURL = `${BaseURL}/api/getProductListSearchForAddon`;
/***************************************************************************************/
export const GetCustomersURL = `${BaseURL}/api/getCustomar`;
export const SearchCustomersURL = `${BaseURL}/api/searchCustomer`;

/***************************************************************************************/
export const GetManageTablesURL = `${BaseURL}/api/getManageTable`;
export const UpdateManageTableNameURL = `${BaseURL}/api/updateTable`;
export const UpdateQrEnableURL = `${BaseURL}/api/updateQr`;
export const GetManageTableCategoryURL = `${BaseURL}/api/getTableCategory`;
export const AddManageTableCategoryURL = `${BaseURL}/api/addTableCategory`;
export const AddManageTableURL = `${BaseURL}/api/addTable`;
export const UpdateTableCategoryStatusURL = `${BaseURL}/api/updateTableCategoryStatus`;
export const DeleteTableCategoryURL = `${BaseURL}/api/deleteTableCategory`;

/***************************************************************************************/
export const GetOrdersURL = `${BaseURL}/api/getOrder`;
export const GetOrderDetailsURL = `${BaseURL}/api/getOrderDetails`;
export const getDishForOrderEdit = `${BaseURL}/api/getDishForOrderEdit`;
export const orderEdit = `${BaseURL}/api/orderEdit`;


export const GetOrderKOTDetailsURL = `${BaseURL}/api/kotDetailsByOrderId`;
export const GetOrderBillsDetailsURL = `${BaseURL}/api/billsDetailsByOrderId`;

export const CancelOrderURL = `${BaseURL}/api/cancelledReason`;

/***************************************************************************************/
export const GetLiveOrdersURL = `${BaseURL}/api/getLiveOrder`;
export const GetProductListURL = `${BaseURL}/api/getProductList`;
export const SearchProductListURL = `${BaseURL}/api/getProductListSearch`;
export const UpdateLiveOrdersStatusURL = `${BaseURL}/api/updateLiveOrderAction`;
export const DeleteLiveOrderItemURL = `${BaseURL}/api/deleteItems`;
export const ApplyLiveOrderDiscountURL = `${BaseURL}/api/applyDiscount`;
export const UpdateLiveOrderPaymentURL = `${BaseURL}/api/applyPayment`;
export const LiveOrderNewPaymentURL = `${BaseURL}/api/liveOrderNewPayment`;
export const orderSplitBillCreationURL = `${BaseURL}/api/orderSplitBillCreation`;

export const LiveOrderDuePaymentSubmitURl = `${BaseURL}/api/liveOrderDuePaymentSubmit`;

export const paymentMethodChangeURl = `${BaseURL}/api/paymentMethodChange`;



export const UpdateLiveOrderQuantityURL = `${BaseURL}/api/updateQuantity`;
export const AddNewItemInLiveOrderURL = `${BaseURL}/api/newItemAdd`;

/***************************************************************************************/
export const GetQRCodeSettingsURL = `${BaseURL}/api/getQrCode`;
export const UpdateQRCodeSettingsURL = `${BaseURL}/api/saveQrCode`;

/***************************************************************************************/
export const GetFoodersCPDetailsURL = `${BaseURL}/api/getFoodersCp`;
export const UpdateFoodersCPDetailsURL = `${BaseURL}/api/updateFooderCp`;

/***************************************************************************************/
export const GetOnlineDeliverySettingsURL = `${BaseURL}/api/getOnlineDelivery`;
export const AddOnlineDeliverySettingsURL = `${BaseURL}/api/saveOnlineDelivery`;
export const DeleteOnlineDeliverySettingsURL = `${BaseURL}/api/deleteOnlineDelivery`;

/***************************************************************************************/
export const GetStaffListURL = `${BaseURL}/api/getStaff`;
export const SearchStaffURL = `${BaseURL}/api/searchStaff`;
export const GetStaffTypeListURL = `${BaseURL}/api/getStaffTypeList`;
export const AddStaffURL = `${BaseURL}/api/saveStaff`;
export const DeleteStaffURL = `${BaseURL}/api/deleteStaff`;
export const UpdateStaffStatusURL = `${BaseURL}/api/staffStatusUpdate`;
export const GetStaffDetailsURL = `${BaseURL}/api/getsingleStaff`;
export const UpdateStaffDetailsURL = `${BaseURL}/api/updateStaff`;
export const UpdateStaffPasswordURL = `${BaseURL}/api/staffPasswordUpdate`;
export const GetAssignTablesDataForStaffURL = `${BaseURL}/api/getAllTable`;
export const AddAssignTablesForStaffURL = `${BaseURL}/api/addNewAssignTableToStaff`;

/***************************************************************************************/
export const GetPOSDetailsURL = `${BaseURL}/api/getPosDetails`;
export const AddProceedOrderURL = `${BaseURL}/api/savePosDetails`;
export const GetEaterDetailsURL = `${BaseURL}/api/getEatersDetails`;
export const GenerateKOTURL = `${BaseURL}/api/posKOTGeneration`;
export const GetKOTDetailsListURL = `${BaseURL}/api/getKotDetails`;
export const UpdateKOTStatusURL = `${BaseURL}/api/updateKotStatus`;
export const DeleteKOTGeneratedItemURL = `${BaseURL}/api/afterKotItemRemove`;
export const IncreaseKOTGeneratedItemQuantityURL = `${BaseURL}/api/increaseItemQuantityAfterKotGenerate`;
export const DecreaseKOTGeneratedItemQuantityURL = `${BaseURL}/api/decreaseItemQuantityAfterKotGenerate`;
export const SettleDineOrderForCashPaymentURL = `${BaseURL}/api/posPaymentDetailsUpdateForDineIn`;
export const SettleDeliveryAndPickUpOrderForCashPaymentURL = `${BaseURL}/api/posCashPaymentDetailsUpdateForDelivaryAndPickUp`;
export const SettleDeliveryAndPickUpOrderForUnpaidPaymentURL = `${BaseURL}/api/posUnpaidDetails`;
export const UpdateSaveOrderItemsURL = `${BaseURL}/api/afterOrderGeneratedAddNewItems`;


export const partialPaymentForDineIn = `${BaseURL}/api/partialPaymentForDineIn`;
export const partialPaymentForDelivaryAndTakeAway = `${BaseURL}/api/partialPaymentForDelivaryAndTakeAway`;

export const switchTablePosURL = `${BaseURL}/api/switchTablePos`;
export const switchTableKOTUpdateURL = `${BaseURL}/api/switchTableKOTUpdate`;

export const checkTableCommittedURL = `${BaseURL}/api/checkTableCommitted`;
export const clearTablesURL = `${BaseURL}/api/clearTables`;
export const updateCustomerDataURL = `${BaseURL}/api/updateCustomerData`;

export const orderWwhatssappMmassageHistoryURL = `${BaseURL}/api/orderWhatssappMmassageHistory`;








/***************************************************************************************/
export const GetVerificationDetailsURL = `${BaseURL}/api/getVerificationDetails`;
export const AddVerificationDetailsURL = `${BaseURL}/api/saveVerificationDetails`;

/***************************************************************************************/
export const UpdateProductVariantsURL = `${BaseURL}/api/updateVariants`;
export const AddNewProductVariantURL = `${BaseURL}/api/addVariants`;
export const UpdateSingleProductVariantURL = `${BaseURL}/api/updateSingleVariant`;
export const DeleteProductVariantURL = `${BaseURL}/api/deleteSingleVariant`;

/***************************************************************************************/
export const GetRawMaterialUnitListURL = `${BaseURL}/api/listRawMaterialsDetails`;
export const AddRawMaterialURL = `${BaseURL}/api/rawMaterials`;
export const GetRawMaterialListURL = `${BaseURL}/api/rawMaterials`;
export const DeleteRawMaterialURL = `${BaseURL}/api/deleteRawMaterials`;
export const UpdateRawMaterialURL = `${BaseURL}/api/rawMaterials`;
export const UpdateRawMaterialStatusURL = `${BaseURL}/api/updateStatus`;
export const GetSingleRawMaterialURL = `${BaseURL}/api/singleRawMaterials`;

/***************************************************************************************/
export const GetSuppliersListURL = `${BaseURL}/api/suppliers`;
export const AddSupplierURL = `${BaseURL}/api/suppliers`;
export const DeleteSupplierURL = `${BaseURL}/api/deleteSuppliers`;
export const UpdateSupplierStatusURL = `${BaseURL}/api/updateSupplierStatus`;
export const UpdateSupplierURL = `${BaseURL}/api/suppliers`;
export const GetRawMaterialListForSuppliersURL = `${BaseURL}/api/listRawMaterials`;
export const GetSingleSupplierURL = `${BaseURL}/api/singleSuppliers`;

/***************************************************************************************/
export const GetProductAndVariantsURL = `${BaseURL}/api/selectList`;
export const AddRecipeURL = `${BaseURL}/api/saveDishRecipes`;
export const GetAllRecipesListURL = `${BaseURL}/api/getDishRecipes`;
export const GetSingleRecipeURL = `${BaseURL}/api/getSingleDishRecipes`;
export const UpdateRecipeURL = `${BaseURL}/api/updateDishRecipes`;
export const DeleteRecipeURL = `${BaseURL}/api/deleteDishRecipes`;
export const UpdateRecipeStatusURL = `${BaseURL}/api/updateDishRecipesStatus`;
export const UpdateRecipeItemURL = `${BaseURL}/api/updateDishRecipesItem`;
export const AddRecipeItemURL = `${BaseURL}/api/addDishRecipesItems`;
export const DeleteRecipeItemURL = `${BaseURL}/api/deleteFromFrontEndDishRecipesItem`;

/***************************************************************************************/
export const GetAllStockListURL = `${BaseURL}/api/getStock`;

/***************************************************************************************/
export const GetSupplierAndRawMaterialListURL = `${BaseURL}/api/getSupplierAndRawMaterialList`;
export const GetInvoiceListURL = `${BaseURL}/api/purchaseOrder`;
export const GetSingleInvoiceDataURL = `${BaseURL}/api/singlePurchaseOrder`;
export const CreateInvoiceURL = `${BaseURL}/api/purchaseOrder`;
export const UpdateInvoiceURL = `${BaseURL}/api/updatePurchaseOrder`;
export const DeleteInvoiceURL = `${BaseURL}/api/deletePurchaseOrder`;
export const DeleteInvoiceItemURL = `${BaseURL}/api/deletePurchaseOrderItem`;
export const AddInvoiceItemURL = `${BaseURL}/api/purchaseOrderItem`;
export const UpdateInvoiceItemURL = `${BaseURL}/api/updatePurchaseItem`;
export const ApproveInvoiceURL = `${BaseURL}/api/calculatePurchaseOrder`;
export const PaymentInvoiceURL = `${BaseURL}/api/purchasePayment`;

/***************************************************************************************/
export const SearchPurchaseOrderURL = `${BaseURL}/api/getPurchaseOrderListSearch`;
export const GetPurchaseReturnListURL = `${BaseURL}/api/getPurchaseReturn`;
export const GetPurchaseReturnNumberURL = `${BaseURL}/api/getPR`;
export const DeletePurchaseReturnURL = `${BaseURL}/api/deletePurchaseReturn`;
export const ApprovePurchaseReturnURL = `${BaseURL}/api/calculatePurchaseOrderReturnStock`;
export const CreatePurchaseReturnURL = `${BaseURL}/api/savePurchaseOrderReturn`;
export const GetSinglePurchaseReturnDataURL = `${BaseURL}/api/singlePurchaseReturn`;

/***************************************************************************************/
export const GetCouponCodeListURL = `${BaseURL}/api/couponCode`;
export const AddCouponCodeURL = `${BaseURL}/api/couponCode`;
export const UpdateCouponCodeURL = `${BaseURL}/api/couponCode`;
export const UpdateCouponCodeStatusURL = `${BaseURL}/api/updateStatusCouponCode`;
/***************************************************************************************/
export const GetGSTReportURL = `${BaseURL}/api/gstReport`;
export const GetReportByOrderTypeURL = `${BaseURL}/api/orderReport`;
export const GetSalesReportDailyURL = `${BaseURL}/api/salesReportDaily`;
export const GetCustomisedSalesReportURL = `${BaseURL}/api/customisedSalesReport`;

export const GetItemsReportURL = `${BaseURL}/api/getItemsReportDetails`;



/***************************************************************************************/
export const GetFoodersPlans = `${BaseURL}/api/getFoodersPlans`;



/***************************************************************************************/
// export const ScriptSRC = "https://alertify.live/notifier.js?data=devnode_"; // For "https://fooders.scanka.com"
export const ScriptSRC = "https://alertify.live/notifier.js?data=livenode_"; // For "https://fooders.khateraho.com"


export const ScriptId = "alertify-notifier-script";
export const OrderAcceptTime = 60;
export const defaultWhatsappApiAccessToken = "9bN9Xhud78Dtnci2mkhv8cbxF67XLIAcsFe4VCGFy61NoRe2o4fRv2zRAPuXDL1y"; // For "Default Whatsapp Api Access Token"
export const vayureachLink = "https://iwcn.us/auth/login"; // For "vayureach Link"

