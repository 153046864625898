import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { clearTablesURL, ScriptId } from "../../assets/api/BaseURL";
import axios from "axios";

const SideBar = ({ active, toggleCanvas }) => {
  const [planDetails, setPlanDetails] = useState(null);
  const [logoutModel, setLogoutModel] = useState(false);
  const [showNewTag, setShowNewTag] = useState(false);


  const handleLogout = async (tableClear) => {
    // e.preventDefault();
    let token = localStorage.getItem("token");

    localStorage.removeItem("token");
    localStorage.removeItem("planDetails");
    localStorage.removeItem("userId");
    localStorage.removeItem("currencySymbol");
    if (tableClear) {
      console.log(token)
      try {
        const response = await axios({
          method: "put",
          url: clearTablesURL,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
      }
      localStorage.clear()
    }
    const existingScript = document.getElementById(ScriptId);
    if (existingScript) {
      document.head.removeChild(existingScript);
    }
    window.location.href = "/";
  };

  useEffect(() => {
    if (localStorage.getItem("planDetails")) {
      setPlanDetails(JSON.parse(localStorage.getItem("planDetails")));
    }

    if (!localStorage.getItem("new_tag")) {
      setShowNewTag(true)
      // localStorage.setItem("new_tag", 0)
    }
  }, []);
  useEffect(() => {
     
    if (active === "whatsapp_marketing") {
      setShowNewTag(false)
      localStorage.setItem("new_tag", 0)
    }
  }, [active]);

  
  return (
    <>
      <div
        className={logoutModel ? "modal fade show d-block" : "modal fade"}
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content w-85">
            <div className="modal-header py-2">
              <h5 className="modal-title" id="exampleModalLabel">
                Logout
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => { setLogoutModel(false) }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body py-2">
              <div className="clear_and_logout_text_padding">
                <h6>
                  Clicking on the “Clear and Logout” buttons will clear the tables.
                </h6>
              </div>
            </div>


            <div className="modal-footer" style={{ padding: "15px 0px" }}>
              <div className="row" style={{ width: "100%" }}>
                <div className="col-3 valign">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={() => { handleLogout(false) }}
                  >
                    Logout
                  </button>{' '}

                </div>
                <div className="col-8 valign">
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                    onClick={() => { handleLogout(true) }}
                  >
                    Clear and Logout
                  </button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav
        className={
          toggleCanvas
            ? "sidebar sidebar-offcanvas active"
            : "sidebar sidebar-offcanvas"
        }
        id="sidebar"
      >
        <div className="menuscrl">
          <ul className="nav">
            {planDetails && planDetails.dashboard === 1 && (
              <Link
                to="/dashboard"
                className={
                  active === "dashboard" ? "nav-item active" : "nav-item"
                }
              >
                <span className="nav-link">
                  <i className="mdi mdi-grid-large menu-icon"></i>
                  <span className="menu-title">Dashboard</span>
                </span>
              </Link>
            )}






            {planDetails && planDetails.live_orders === 1 && (
              <Link
                to="/live_orders"
                className={
                  active === "live_orders" ? "nav-item active" : "nav-item"
                }
              >
                <span className="nav-link">
                  <i className="mdi mdi-speedometer-medium menu-icon"></i>
                  <span className="menu-title"> Live Orders</span>
                </span>
              </Link>
            )}

            {/* {planDetails && planDetails.pos === 1 && (
              <Link
                className="nav-item d-block d-md-none d-sm-none d-lg-none"
                to="/pos"
              >
                <span className="nav-link">
                  <i className="mdi mdi-monitor-dashboard menu-icon" />
                  <span className="menu-title">POS</span>
                </span>
              </Link>
            )} */}

            {planDetails && planDetails.staff_manager === 1 && (
              <Link
                to="/view_staff"
                className={
                  active === "view_staff" ? "nav-item active" : "nav-item"
                }
              >
                <span className="nav-link">
                  <i className="menu-icon mdi mdi-account"></i>
                  <span className="menu-title">Staff</span>
                </span>
              </Link>
            )}

            {planDetails && planDetails.menu_products === 1 && (
              <li
                className={
                  active === "view_menus" ||
                    active === "view_products" ||
                    active === "add_ons"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <a
                  className={
                    active === "view_menus" ||
                      active === "view_products" ||
                      active === "add_ons"
                      ? "nav-link"
                      : "nav-link collapsed"
                  }
                  data-bs-toggle="collapse"
                  href="#ui-basic1"
                  aria-expanded={
                    active === "view_menus" ||
                      active === "view_products" ||
                      active === "add_ons"
                      ? true
                      : false
                  }
                  aria-controls="ui-basic1"
                >
                  <i className="menu-icon mdi mdi-floor-plan"></i>
                  <span className="menu-title">Manage Dishes</span>
                  <i className="menu-icon menu-arrow"></i>
                </a>
                <div
                  className={
                    active === "view_menus" ||
                      active === "view_products" ||
                      active === "add_ons"
                      ? "collapse show"
                      : "collapse"
                  }
                  id="ui-basic1"
                >
                  <ul className="nav flex-column sub-menu">
                    <Link
                      to="/view_menus"
                      className={
                        active === "view_menus" ? "nav-item active" : "nav-item"
                      }
                    >
                      <span className="nav-link">
                        <i className="menu-icon mdi mdi-floor-plan"></i>
                        <span className="menu-title">Category/Menu</span>
                      </span>
                    </Link>
                    <Link
                      to="/view_products"
                      className={
                        active === "view_products"
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <span className="nav-link">
                        <i className="menu-icon mdi mdi-silverware-fork-knife"></i>
                        <span className="menu-title">Dishes/Products</span>
                      </span>
                    </Link>
                    <Link
                      to="/add_ons"
                      className={
                        active === "add_ons" ? "nav-item active" : "nav-item"
                      }
                    >
                      <span className="nav-link">
                        <i className="menu-icon mdi mdi-cookie"></i>
                        <span className="menu-title">Add Ons</span>
                      </span>
                    </Link>
                  </ul>
                </div>
              </li>
            )}

            {/* <Link
            to="/manage_variants"
            className={
              active === "manage_variants" ? "nav-item active" : "nav-item"
            }
          >
            <span className="nav-link">
              <i className="menu-icon mdi mdi-table"></i>
              <span className="menu-title">Manage Variants / Addons</span>
            </span>
          </Link> */}

            {planDetails && planDetails.orders === 1 && (
              <Link
                to="/orders"
                className={active === "orders" ? "nav-item active" : "nav-item"}
              >
                <span className="nav-link">
                  <i className="menu-icon mdi mdi-cart"></i>
                  <span className="menu-title">Orders</span>
                </span>
              </Link>
            )}

            {planDetails && planDetails.inventory === 1 && (
              <li
                className={
                  active === "raw_material" ||
                    active === "suppliers" ||
                    active === "recipes" ||
                    active === "stocks" ||
                    active === "view_purchase" ||
                    active === "view_purchase_return"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <a
                  className={
                    active === "raw_material" ||
                      active === "suppliers" ||
                      active === "recipes" ||
                      active === "stocks" ||
                      active === "view_purchase" ||
                      active === "view_purchase_return"
                      ? "nav-link"
                      : "nav-link collapsed"
                  }
                  data-bs-toggle="collapse"
                  href="#ui-basic"
                  aria-expanded={
                    active === "raw_material" ||
                      active === "suppliers" ||
                      active === "recipes" ||
                      active === "stocks" ||
                      active === "view_purchase" ||
                      active === "view_purchase_return"
                      ? true
                      : false
                  }
                  aria-controls="ui-basic"
                >
                  <i className="menu-icon mdi mdi-store"></i>
                  <span className="menu-title">Inventory</span>
                  <i className="menu-icon menu-arrow"></i>
                </a>
                <div
                  className={
                    active === "raw_material" ||
                      active === "suppliers" ||
                      active === "recipes" ||
                      active === "stocks" ||
                      active === "view_purchase" ||
                      active === "view_purchase_return"
                      ? "collapse show"
                      : "collapse"
                  }
                  id="ui-basic"
                >
                  <ul className="nav flex-column sub-menu">
                    <Link
                      to="/raw_material"
                      className={
                        active === "raw_material"
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <span className="nav-link">
                        <i className="menu-icon mdi mdi-store"></i>
                        <span className="menu-title">Raw Material</span>
                      </span>
                    </Link>
                    <Link
                      to="/suppliers"
                      className={
                        active === "suppliers" ? "nav-item active" : "nav-item"
                      }
                    >
                      <span className="nav-link">
                        <i className="menu-icon mdi mdi-truck"></i>
                        <span className="menu-title">Suppliers</span>
                      </span>
                    </Link>
                    <Link
                      to="/recipes"
                      className={
                        active === "recipes" ? "nav-item active" : "nav-item"
                      }
                    >
                      <span className="nav-link">
                        <i className="menu-icon mdi mdi-notebook-outline"></i>
                        <span className="menu-title">Recipes</span>
                      </span>
                    </Link>
                    <Link
                      to="/stocks"
                      className={
                        active === "stocks" ? "nav-item active" : "nav-item"
                      }
                    >
                      <span className="nav-link">
                        <i className="menu-icon mdi mdi-store-24-hour"></i>
                        <span className="menu-title">Stocks</span>
                      </span>
                    </Link>
                    <Link
                      to="/view_purchase"
                      className={
                        active === "view_purchase"
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <span className="nav-link">
                        <i className="menu-icon mdi mdi-wallet-outline"></i>
                        <span className="menu-title">Purchases</span>
                      </span>
                    </Link>
                    <Link
                      to="/view_purchase_return"
                      className={
                        active === "view_purchase_return"
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <span className="nav-link">
                        <i className="menu-icon mdi mdi-share"></i>
                        <span className="menu-title">Purchase Returns</span>
                      </span>
                    </Link>
                  </ul>
                </div>
              </li>
            )}

            {planDetails && planDetails.fooder_cp === 1 && (
              <Link
                to="/fooders_cp"
                className={
                  active === "fooders_cp" ? "nav-item active" : "nav-item"
                }
              >
                <span className="nav-link">
                  <i className="menu-icon mdi mdi-airballoon"></i>
                  <span className="menu-title">Fooders CP</span>
                </span>
              </Link>
            )}

            {planDetails && planDetails.fooder_cp === 1 && (
              <Link
                to="/whatsapp_marketing"
                className={
                  active === "whatsapp_marketing" ? "nav-item active" : "nav-item"
                }
              >
                <span className="nav-link">
                  <i className="menu-icon mdi mdi-whatsapp"></i>
                  <span className="menu-title">WhatsApp Marketing</span>&ensp;
                  {active === "whatsapp_marketing"  || !showNewTag ? <></> : <>
                  {/* <span className="badge bg-success" style={{ fontSize: "10px" }} ><b>New</b></span> */}
                  <p style={{backgroundColor:"green", paddingLeft:"3px", paddingRight:"3px", paddingTop:"0px", paddingBottom:"0px", borderRadius:"5px", color:'white', fontSize:"10px"}}><b>New</b></p>
                  </>}

                </span>
              </Link>
            )}

            {planDetails && planDetails.customers === 1 && (
              <Link
                to="/customers"
                className={
                  active === "customers" ? "nav-item active" : "nav-item"
                }
              >
                <span className="nav-link">
                  <i className="menu-icon mdi mdi-account-group"></i>
                  <span className="menu-title">Customers</span>
                </span>
              </Link>
            )}

            {/* {planDetails && planDetails.verififation_documents === 1 && (
              <Link
                to="/verification"
                className={
                  active === "verification" ? "nav-item active" : "nav-item"
                }
              >
                <span className="nav-link">
                  <i className="menu-icon mdi mdi-file-document"></i>
                  <span className="menu-title">Verification</span>
                </span>
              </Link>
            )} */}

            {planDetails && planDetails.manage_tables === 1 && (
              <Link
                to="/manage_tables"
                className={
                  active === "manage_tables" ? "nav-item active" : "nav-item"
                }
              >
                <span className="nav-link">
                  <i className="menu-icon mdi mdi-table"></i>
                  <span className="menu-title">Manage Tables</span>
                </span>
              </Link>
            )}

            {planDetails && planDetails.callwaiter_settings === 1 && (
              <Link
                to="/qrcode_settings"
                className={
                  active === "qrcode_settings" ? "nav-item active" : "nav-item"
                }
              >
                <span className="nav-link">
                  <i className="menu-icon mdi mdi-qrcode-edit"></i>
                  <span className="menu-title">QR Code Settings</span>
                </span>
              </Link>
            )}

            {/* {planDetails && planDetails.online_delivery_settings === 1 && (
              <Link
                to="/online_ordering"
                className={
                  active === "online_ordering" ? "nav-item active" : "nav-item"
                }
              >
                <span className="nav-link">
                  <i className="menu-icon mdi mdi-truck"></i>
                  <span className="menu-title">Delivery Settings</span>
                </span>
              </Link>
            )} */}

            {planDetails && planDetails.coupons === 1 && (
              <Link
                to="/manage_coupons"
                className={
                  active === "manage_coupons" ? "nav-item active" : "nav-item"
                }
              >
                <span className="nav-link">
                  <i className="menu-icon mdi mdi-ticket-percent"></i>
                  <span className="menu-title">Manage Coupons</span>
                </span>
              </Link>
            )}

            {planDetails && planDetails.reports === 1 && (
              <Link
                to="/reports"
                className={
                  active === "reports" ? "nav-item active" : "nav-item"
                }
              >
                <span className="nav-link">
                  <i className="menu-icon mdi mdi-file-chart"></i>
                  <span className="menu-title">Reports</span>
                </span>
              </Link>
            )}

            <Link
              to="/account_management"
              className={
                active === "account_management" ? "nav-item active" : "nav-item"
              }
            >
              <span className="nav-link">
                <i className="menu-icon mdi mdi-account-circle-outline"></i>
                <span className="menu-title">Profile</span>
              </span>
            </Link>

            <Link
              to="/updates"
              className={
                active === "update" ? "nav-item active" : "nav-item"
              }
            >
              <span className="nav-link">
                <i className="menu-icon mdi mdi-pencil-box"></i>
                <span className="menu-title">Updates</span>
              </span>
            </Link>

            <Link className="nav-item"
              // onClick={handleLogout}
              onClick={() => { setLogoutModel(true) }}

            >
              <span className="nav-link">
                <i className="menu-icon mdi mdi-logout"></i>
                <span className="menu-title">Logout</span>
              </span>
            </Link>
          </ul>
          <span className="text-black fw-bold versioncss">Version : V3.0.3</span>
        </div>
      </nav>
    </>
  );
};

export default SideBar;
